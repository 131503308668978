import { useStaticQuery, graphql } from "gatsby"

export const useRssBlogFeed = (): Array<{
  title: string
  link: string
  content: string
  creator: string
}> => {
  const { allFeedIglooBlog } = useStaticQuery(
    graphql`
      query BlogRSSQuery {
        allFeedIglooBlog {
          nodes {
            title
            link
            content
            creator
          }
        }
      }
    `
  )
  return allFeedIglooBlog.nodes
}
