//TODO JAKMILE BUDE NÁVRH, UPRAV VERZI PRO MOBIL,HOVER STAVY...
import React from "react"
import { Helmet } from "react-helmet"
import {
  TeamProfile,
  TeamCollection,
  ArticlesList,
  BottomFloeWrapper,
  ContainerBackground,
  ContainerBackgroundStyle,
} from "@igloonet-web/shared-ui"
import { useRssBlogFeed } from "../hooks/use-rss-blog-feed"
import styled from "styled-components"

const CustomContainer = styled.div`
  overflow-x: hidden;

  .negativeMargin {
    z-index: -1;
  }

  .padding {
    padding-top: 10rem;
  }
`

const MarginBottom = styled.div`
  margin-bottom: -10rem;
`

const ActiveEmployee: React.FC<ActiveEmployeeProps> = ({
  employee,
  employees,
  teamName,
  profileImageTag,
  alt,
}) => {
  const rss = useRssBlogFeed()

  const filteredArticles = rss.filter(
    (blog) => blog.creator === employee.name + " " + employee.surname
  )

  const sheOrHe = employee.sex === "male" ? "" : "a"

  return (
    <>
      <Helmet>
        <title>Tučňák {employee.nickname} | igloonet</title>
      </Helmet>

      <CustomContainer>
        <TeamProfile
          profileImageTag={profileImageTag}
          employee={employee}
          alt={alt}
        />

        {filteredArticles.length !== 0 ? (
          <MarginBottom className="mx-3">
            <ArticlesList
              sexAndNameOfCreator={sheOrHe + " " + employee.name}
              rss={filteredArticles}
            />
          </MarginBottom>
        ) : null}

        <BottomFloeWrapper className="flatFloe">
          <ContainerBackground
            style={ContainerBackgroundStyle.TertiaryBG}
            className={`pb-0 w-100 ${
              filteredArticles.length !== 0 ? "padding" : ""
            }`}
          >
            <>
              {employee.teams.map((team, i) => {
                return (
                  <>
                    <TeamCollection
                      key={i}
                      teamMembers={employees.filter(
                        (e) =>
                          e.teams.includes(team) && e.login !== employee.login
                      )}
                      imageTag={profileImageTag}
                      alt={alt}
                      title={`Kolegové z týmu ${teamName(team)}`}
                    />
                  </>
                )
              })}
            </>
          </ContainerBackground>
        </BottomFloeWrapper>
      </CustomContainer>
    </>
  )
}

export default ActiveEmployee
